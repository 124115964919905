import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Section from "./Section";
import TextField from "./TextField";
import Button from "./Button";
import { Link } from "./../util/router";
import newsletter from "./../util/newsletter";

function NewsletterSection(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <div className="mx-auto relative text-center max-w-lg">
          <div>
            <h3 className="text-2xl sm:text-3xl font-extrabold mb-2">
              {props.title}
            </h3>
            <p className="prose prose-indigo mb-6">{props.subtitle}</p>

            {subscribed === true && (
              <div className="mt-3 text-center">You are now subscribed!</div>
            )}

            {subscribed === false && (
              <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex items-start space-x-3">
                  <TextField
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your email"
                    error={errors.email}
                    inputRef={register({
                      required: "Please enter an email address",
                    })}
                  />
                  <Button type="submit" size="lg">
                    Subscribe
                  </Button>
                </div>
                <p className="text-sm text-gray-600 leading-6">
                  No spam ever, unsubscribe at any time.
                  <br />
                  Check out our{" "}
                  <Link
                    to="/legal/privacy-policy"
                    className="font-medium text-blue-600 hover:text-blue-400"
                  >
                    Privacy Policy
                  </Link>
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default NewsletterSection;
