import React, { useState, useEffect } from "react";

export const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted or declined cookies
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true); // Show the banner only if no consent is found
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true"); // Save consent
    setIsVisible(false); // Hide banner
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false"); // Save refusal
    setIsVisible(false); // Hide banner
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    isVisible && (
      <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 z-50 flex flex-col md:flex-row justify-between items-start md:items-center">
        <div className="flex-1">
          <p className="text-sm">
            We value your privacy, so we use cookies to enhance your experience.{" "}
            <a href="/privacy-policy" className="underline">
              Learn more
            </a>.
          </p>
          <button
            onClick={toggleExpand}
            className="text-blue-400 underline mt-2">
            {isExpanded ? "Hide cookie details" : "Show cookie details"}
          </button>
          {isExpanded && (
            <ul className="list-disc ml-4 mt-2 text-sm">
              <li>
                <strong>__stripe_mid</strong>: Tracks the user's session for Stripe
                (fraud prevention and detection, United States).
              </li>
              <li>
                <strong>__stripe_sid</strong>: Session identifier for Stripe (fraud
                prevention and detection, United States).
              </li>
              <li>
                <strong>Auth Token</strong>: Used to securely authenticate your session.
              </li>
              <li>
              <strong>m</strong>: Tracks the user's session for Stripe
            </li>
            </ul>
          )}
        </div>
        <div className="flex mt-4 md:mt-0">
          <button
            onClick={handleAccept}
            className="bg-blue-500 text-white px-4 py-2 mr-2 rounded hover:bg-blue-600 transition">
            Accept
          </button>
          <button
            onClick={handleDecline}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition">
            Decline
          </button>
        </div>
      </div>
    )
  );
};
