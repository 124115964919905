import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import { useAuth } from "../util/auth";
import { useRouter } from "./../util/router";
import { CookieBanner } from "./../components/CookieBanner";
// import FeaturesSection from "./../components/FeaturesSection";
// import ClientsSection from "./../components/ClientsSection";
// import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import HeroSection from "./../components/HeroSection"

function IndexPage(props) {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if(auth.user) {
      router.push("/dashboard");
    }
  }, [auth]);

  return (
    <>
      <Meta />
      <CookieBanner />

      <HeroSection
        title="Discover Local Treasures & Exclusive Savings"
        subtitle="Support local businesses, enjoy exclusive discounts, and explore a marketplace where every purchase makes a difference. Shop with purpose, uplift your community, and find exceptional deals you won't get anywhere else!"
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <NewsletterSection
        title="Subscribe to our newsletter"
        subtitle="Join us and receive the best curated news, freebies and resources directly to your inbox every week!"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> 
      {/* <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800">
        <img
          className="w-full h-96 mb-10 lg:w-96 lg:h-auto object-cover rounded-lg mx-auto shadow-lg"
          src="/CRESCENT_CONVENIENCE.png"
          alt="Crescent Convenience"
        />
        
        <h1 className="text-3xl font-semibold text-center">
          Coming Soon...
        </h1>

        <footer className="mt-10 w-full bg-gray-50 py-8">
          <p className="text-lg font-medium text-gray-700 text-center mb-4">
            In the meantime, follow us on
          </p>
          <div className="flex items-center justify-center gap-6">

            <a
              href="https://www.facebook.com/profile.php?id=61568604030360"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-blue-600 transition transform hover:scale-110"
              aria-label="Facebook"
            >
              <FaFacebookF size={32} />
            </a>

            <a
              href="https://www.instagram.com/crescentconvenience"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-pink-500 transition transform hover:scale-110"
              aria-label="Instagram"
            >
              <FaInstagram size={32} />
            </a>
          </div>
        </footer>
      </div> */}
    </>
  );
}

export default IndexPage;
