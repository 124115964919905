import React from "react";
import {
  CubeTransparentIcon,
  HeartIcon,
  ArrowDownIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

import { ReactSVG } from "react-svg";

function HeroSection2(props) {
  const items = [
    {
      title: "Purpose",
      description:
        "We are dedicated to uplifting small locally owned businesses and helping you discover the best deals with ease. Our focus is on creating a community-driven platform that not only saves you money but also supports your values. Every detail matters, and we're committed to bringing you exclusive discounts and a seamless shopping experience.",
      icon: HeartIcon,
      iconColor: "text-red-500",
    },
    {
      title: "Commitment",
      description:
        "We're committed to supporting locally owned businesses and delivering the best value to you. Our goal is to continually improve our platform, offering you exclusive discounts and a simple, user-friendly experience. We stand by our mission to make shopping purposeful, bringing you great deals while making a positive impact.",
      icon: ArrowDownIcon,
      iconColor: "text-blue-500",
    },
    // {
    //   title: "Less is more",
    //   description:
    //     "We believe that design should be invisible and enhance the user experience, not get in the way. This gives room for your content to breath and attracts your users’ attention.",
    //   icon: CubeTransparentIcon,
    //   iconColor: "text-pink-500",
    // },
  ];

  return (
    <>
      <div style={{ marginTop: "-20px" }}>
      </div>
      <div className="space-y-16 container">
        <div>
          <div style={{ textAlign: "-webkit-center"}}>
          <img
            className="w-full h-96 lg:w-96 lg:h-auto relative object-cover mx-auto"
            src="/CRESCENT_CONVENIENCE.png"
            alt=""
          />
          </div>

          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
            className="lg:w-1/2"
          />
        </div>
        <div className="flex space-x-2">
          <div>
            <img src={props.leftImage} alt="" className="rounded-lg" />
          </div>
          <div>
            <img src={props.rightImage} alt="" className="rounded-lg" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {items.map((item, index) => (
            <div key={index}>
              <h3 className="flex items-center space-x-2 text-lg font-bold uppercase tracking-wide mb-2">
                <item.icon
                  className={
                    "inline-block w-5 h-5" +
                    (item.iconColor ? ` ${item.iconColor}` : "")
                  }
                />
                <span>{item.title}</span>
              </h3>
              <p className="leading-relaxed text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default HeroSection2;
