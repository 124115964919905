import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OpeningTimes from "./OpeningTimes"; // Import the OpeningTimes component
import { SocialMediaIcons } from "./SocialMediaIcons"; // Import the SocialMediaIcons component
import StarRating from "./StarRating";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/solid";

const CardModal = ({ cardContent, handleCloseModal }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false); // State for toggling description

  const businessLocation = {
    lat: cardContent.coordinates.latitude,
    lng: cardContent.coordinates.longitude,
  };

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  const truncatedDescription = `${cardContent.description.slice(0, 100)}...`;

  const menuUrl =
    cardContent?.images.find((imageUrl) => imageUrl.includes("menu.pdf")) ||
    null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="relative bg-white w-full h-full md:max-w-lg rounded-lg shadow-lg overflow-hidden">
        <div className="flex flex-col h-full">
          <div className="w-full h-48 md:h-64 mb-4 sticky top-0 z-10">
            {" "}
            {/* Map remains sticky */}
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              {" "}
              {/* Replace with your actual API key */}
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={businessLocation}
                zoom={16}
              >
                <Marker position={businessLocation} />
              </GoogleMap>
            </LoadScript>
          </div>

          <div className="p-4 flex-grow flex flex-col overflow-y-auto overflow-x-hidden">
            {" "}
            {/* Prevent horizontal overflow */}
            <h2 className="text-2xl text-black mb-2 font-bold">
              {cardContent.name}
            </h2>
            <p className="text-lg text-gray-700 mb-2">{cardContent.address}</p>
            <div className="flex justify-center mb-3">
              <StarRating rating={cardContent.rating} />
            </div>
            <SocialMediaIcons
              address={cardContent?.address}
              contact={cardContent.contact}
            />
            {/* Image carousel using React Slick */}
            <div className="mb-6">
              <Slider {...sliderSettings}>
                {cardContent?.images?.map((image, index) => (
                  <div key={index} className="relative w-full h-64 md:h-80">
                    <img
                      className="absolute inset-0 w-full h-full object-cover rounded-md"
                      src={image}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            {/* <div className="pt-3">
              <div className="bg-yellow-100 p-4 rounded-md shadow-sm flex items-center">
                <p className="text-base text-yellow-900 leading-relaxed">
                  <strong>Allergen Information:</strong> Please inform us if you
                  have any allergies. This business ensures allergen safety, but
                  cross-contamination may occur.
                </p>
              </div>
            </div> */}
            {/* Business Description Section */}
            <div className="pt-3">
              <div className="border-t border-gray-200 my-4"></div>{" "}
              {menuUrl && (
                <button className="bg-customBeige justify-center w-full text-center text-black px-4 py-2 mb-4 rounded flex items-center text-sm md:text-base hover:bg-opacity-80">
                  <a
                    href={menuUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center w-full justify-center"
                    download
                  >
                    <ArrowDownOnSquareIcon className="mr-2 w-6 h-6" />
                    Menu
                  </a>
                </button>
              )}
              {/* Divider */}
              <div className="bg-gray-100 p-4 rounded-md shadow-sm">
                {" "}
                {/* Styled container */}
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  About {cardContent.title}
                </h3>
                <p className="text-base text-gray-600 mb-4 leading-relaxed">
                  {isDescriptionExpanded
                    ? cardContent.description
                    : cardContent.description.length > 100
                    ? truncatedDescription
                    : cardContent.description}
                </p>
                {cardContent.description.length > 100 && (
                  <button
                    onClick={() =>
                      setIsDescriptionExpanded(!isDescriptionExpanded)
                    }
                    className="text-blue-500 text-sm"
                  >
                    {isDescriptionExpanded ? "Show Less" : "Read More"}
                  </button>
                )}
              </div>
            </div>
            {/* Opening Times Section */}
            <div className="pt-3">
              <OpeningTimes hours={cardContent.opening_times} />
            </div>
            {/* Sticky button at the bottom of the modal */}
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-customBeige px-4 py-2 rounded text-sm md:text-base"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardModal;
