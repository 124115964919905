import React, { useState, useEffect } from "react";
import { DismissableBox } from "./DismissableBox";

export const SaveToApp = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Check platform type
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isMobile = isAndroid || isIOS;

  // Check localStorage for dismiss status
  useEffect(() => {
    const dismissed = localStorage.getItem("dismissableBoxDismissed");
    if (isMobile && !dismissed) {
      setIsVisible(true);
    }
  }, [isMobile]);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem("dismissableBoxDismissed", "true");
  };

  const androidMessage =
    "Tap the menu button (three dots) in your browser, then select 'Add to Home screen' to save this page as an app.";
  const iosMessage =
    "Tap the share button in Safari, then select 'Add to Home Screen' to save this page as an app.";

  const message = isAndroid ? androidMessage : iosMessage;

  return (
    <DismissableBox
      isVisible={isVisible}
      title="Save this page as an app!"
      message={message}
      onDismiss={handleDismiss}
    />
  );
};
