import React, { useState } from "react";
import QrScanner from "react-qr-scanner";
import { useAuth } from "../util/auth";
import LoadingIcon from "./LoadingIcon";
import { recordTransaction } from "../util/db";

const ModalScanner = ({ handleError, closeModal }) => {
  const [scanResult, setScanResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [transactionCost, setTransactionCost] = useState("");
  const [hasDecimal, setHasDecimal] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [submittedCost, setSubmittedCost] = useState(""); // New state for submitted cost

  const auth = useAuth();
  const discountRate = auth?.user.businesses[0]?.discount_rate || 0; // Default to 0 if undefined

  const handleScan = async (data) => {
    if (data?.text) {
      setScanResult(data.text);
      setLoading(false); // Set loading to false after scan
    }
  };

  const handleCalculatorSubmit = async () => {
    if (transactionCost) {
      try {
        const transactionData = await recordTransaction(
          auth.user.uid,
          auth.user?.businesses[0]?.id,
          transactionCost,
          "GBP",
          scanResult
        );

        setTransactionSuccess(true);
        setSubmittedCost(transactionCost); // Update the submitted cost state

        setTransactionCost("");
        setHasDecimal(false);
      } catch (error) {
        console.error("Error recording transaction:", error);
      }
    } else {
      console.warn("No transaction cost entered.");
    }
  };

  const calculateDiscountedAmount = () => {
    const cost = parseFloat(submittedCost);
    if (!isNaN(cost) && discountRate) {
      const discount = (cost * discountRate) / 100;
      return (cost - discount).toFixed(2); // Return the discounted amount, formatted to 2 decimal places
    }
    return submittedCost; // Return original cost if no discount
  };

  const handleButtonClick = (value) => {
    setTransactionCost((prev) => {
      if (prev.includes(".")) {
        const [whole, decimal] = prev.split(".");
        if (decimal.length >= 2) return prev;
      }
      return prev + value;
    });
  };

  const handleDecimalClick = () => {
    if (transactionCost && !hasDecimal) {
      setTransactionCost((prev) => prev + ".");
      setHasDecimal(true);
    }
  };

  const handleClear = () => {
    setTransactionCost("");
    setHasDecimal(false);
  };

  const renderCalculator = () => (
    <div className="flex flex-col justify-between bg-white w-full h-full">
      <h2 className="text-2xl font-bold mb-6">Enter Transaction Cost</h2>

      <div className="bg-gray-100 p-4 bg-gray-200 shadow-lg w-full">
        <div className="border p-3 text-2xl w-full bg-gray-200 text-gray-900">
          £{transactionCost || "0"}
        </div>
      </div>

      <div className="grid grid-cols-3 w-full flex-grow pt-2">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
          <button
            key={num}
            onClick={() => handleButtonClick(num.toString())}
            className="flex justify-center items-center h-16 bg-gray-200 text-xl hover:bg-gray-300 transition"
          >
            {num}
          </button>
        ))}
        <button
          onClick={() => handleButtonClick("0")}
          className="flex justify-center items-center h-16 bg-gray-200 text-xl col-span-2 hover:bg-gray-300 transition"
        >
          0
        </button>
        <button
          onClick={handleDecimalClick}
          className="flex justify-center items-center h-16 bg-gray-200 text-xl hover:bg-gray-300 transition"
        >
          .
        </button>
      </div>

      <div className="flex justify-between w-full mt-6">
        <div className="flex w-full">
          <button
            onClick={handleClear}
            className="flex justify-center items-center h-16 w-1/3 bg-red-500 text-white shadow-md text-xl hover:bg-red-600 transition"
          >
            C
          </button>

          <button
            onClick={handleCalculatorSubmit}
            className="flex justify-center items-center h-16 w-2/3 bg-green-500 text-white shadow-md text-xl hover:bg-green-600 transition"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex justify-center items-center flex-grow mb-4">
      {!scanResult && !loading && !transactionSuccess && (
        <QrScanner
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%", height: "auto" }}
          constraints={{
            video: {
              facingMode: "environment",
            },
          }}
        />
      )}

      {loading && (
        <div className="flex justify-center items-center">
          <LoadingIcon className="w-8 h-8 text-gray-500" />
        </div>
      )}

      {!loading && scanResult && !transactionSuccess && (
        <div className="w-full h-full flex justify-center items-center">
          {renderCalculator()}
        </div>
      )}

      {transactionSuccess && (
        <div className="flex justify-center items-center w-full h-full">
          <div>
            <span className="text-white text-2xl">
              <img
                className="w-full h-96 lg:w-96 lg:h-auto relative object-cover mx-auto"
                src="/transaction_confirmed.png"
                alt=""
              />
            </span>
            <div className="mt-4 text-center text-gray-800">
              <p>Submitted Cost: £{submittedCost}</p>
              <p>Discount Rate: {discountRate}%</p>
              <p>
                <b>Customer Pays:</b> £{calculateDiscountedAmount()}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalScanner;
