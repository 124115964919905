import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import supabase from "../util/supabase";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AdminTool = () => {
  const [formData, setFormData] = useState({
    id: uuidv4(),
    owner: null,
    name: "",
    address: "",
    description: "",
    opening_times: daysOfWeek.reduce((acc, day) => {
      acc[day] = { open: "", close: "", closed: false };
      return acc;
    }, {}),
    coordinates: { latitude: "", longitude: "" },
    place_id: "",
    contact: {
      phone: "",
      twitter: "",
      website: "",
      facebook: "",
      instagram: "",
    },
    rating: "0",
    type: "",
    short_description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("contact.")) {
      const contactField = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        contact: {
          ...prevData.contact,
          [contactField]: value,
        },
      }));
    } else if (name.startsWith("coordinates.")) {
      const coordField = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        coordinates: {
          ...prevData.coordinates,
          [coordField]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleTimeChange = (day, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      opening_times: {
        ...prevData.opening_times,
        [day]: {
          ...prevData.opening_times[day],
          [field]: value,
        },
      },
    }));
  };

  const handleClosedChange = (day) => {
    setFormData((prevData) => ({
      ...prevData,
      opening_times: [
        {
          ...prevData.opening_times,
          [day]: {
            ...prevData.opening_times[day],
            closed: !prevData.opening_times[day]?.closed,
            open: prevData.opening_times[day]?.closed
              ? prevData.opening_times[day]?.open
              : "",
            close: prevData.opening_times[day]?.closed
              ? prevData.opening_times[day]?.close
              : "",
          },
        },
      ],
    }));
  };

  const handleAddBusiness = async () => {
    try {
      const payload = {
        ...formData,
        coordinates: {
          latitude: parseFloat(formData.coordinates.latitude),
          longitude: parseFloat(formData.coordinates.longitude),
        },
      };

      const response = await fetch("/api/add-business", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();

        const businessId = data.business.id;
        const storagePath = `${businessId}/images/placeholder.jpg`;
        const placeholderFile = new File([""], "placeholder.jpg", {
          type: "image/jpeg",
        });

        const { error: uploadError } = await supabase.storage
          .from("businesses")
          .upload(storagePath, placeholderFile);

        if (uploadError) {
          console.error("Error creating storage path:", uploadError.message);
        } else {
          console.log("Storage path created at:", storagePath);
        }

        setFormData({
          id: uuidv4(),
          owner: null,
          name: "",
          address: "",
          description: "",
          opening_times: daysOfWeek.reduce((acc, day) => {
            acc[day] = { open: "", close: "", closed: false };
            return acc;
          }, {}),
          coordinates: { latitude: "", longitude: "" },
          place_id: "",
          contact: {
            phone: "",
            twitter: "",
            website: "",
            facebook: "",
            instagram: "",
          },
          rating: "0",
          type: "",
          short_description: "",
        });
      } else {
        const errorData = await response.json();
        console.error("Error adding business:", errorData.error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <div className="flex flex-col items-center">
      <h2 className="text-2xl text-black mb-4">Admin Tool</h2>
      <p className="text-gray-700 mb-4">
        Use the tools below to manage your application.
      </p>
      <input
        name="id"
        value={formData.id}
        readOnly
        className="mb-2 p-2 border"
      />
      <input
        name="name"
        placeholder="Business Name (required)"
        value={formData.name}
        onChange={handleChange}
        className="mb-2 p-2 border"
        required
      />
      <input
        name="address"
        placeholder="Address"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <textarea
        name="description"
        placeholder="Description"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <div className="mb-2">
        <h4>Opening Times</h4>
        {daysOfWeek.map((day) => (
          <div key={day} className="flex items-center mb-1">
            <label className="mr-2">{day}</label>
            <input
              type="time"
              value={formData.opening_times[day]?.open}
              onChange={(e) => handleTimeChange(day, "open", e.target.value)}
              disabled={formData.opening_times[day]?.closed}
              className="mr-1 p-1 border"
            />
            <span>to</span>
            <input
              type="time"
              value={formData.opening_times[day]?.close}
              onChange={(e) => handleTimeChange(day, "close", e.target.value)}
              disabled={formData.opening_times[day]?.closed}
              className="ml-1 mr-2 p-1 border"
            />
            <label>
              <input
                type="checkbox"
                checked={formData.opening_times[day]?.closed}
                onChange={() => handleClosedChange(day)}
                className="mr-1"
              />
              Closed
            </label>
          </div>
        ))}
      </div>

      <div className="flex flex-col mb-2">
        <h4>Coordinates</h4>
        <input
          name="coordinates.latitude"
          placeholder="Latitude"
          value={formData.coordinates.latitude}
          onChange={handleChange}
          className="mb-2 p-2 border"
        />
        <input
          name="coordinates.longitude"
          placeholder="Longitude"
          value={formData.coordinates.longitude}
          onChange={handleChange}
          className="mb-2 p-2 border"
        />
      </div>

      <input
        name="place_id"
        placeholder="Place ID"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <input
        name="contact.phone"
        placeholder="Contact Phone"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <input
        name="contact.twitter"
        placeholder="Contact Twitter"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <input
        name="contact.website"
        placeholder="Contact Website"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <input
        name="contact.facebook"
        placeholder="Contact Facebook"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <input
        name="contact.instagram"
        placeholder="Contact Instagram"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <input
        name="rating"
        placeholder="Rating"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <input
        name="type"
        placeholder="Business Type"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <textarea
        name="short_description"
        placeholder="Short Description"
        onChange={handleChange}
        className="mb-2 p-2 border"
      />
      <button
        onClick={handleAddBusiness}
        className="mt-4 bg-customBeige px-4 py-2 rounded"
      >
        Add Business
      </button>
    </div>
  );
};

export default AdminTool;
