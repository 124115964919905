import React, { useContext, createContext, useEffect, useState } from "react";
import { useBusinesses } from "./db";
import LoadingIcon from "../components/LoadingIcon";
import supabase from "./supabase";

const businessContext = createContext();
export const useBusiness = () => useContext(businessContext);

export function BusinessProvider({ children }) {
  const business = useBusinessProvider();
  return (
    <businessContext.Provider value={business}>
      {business.loading ? (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <LoadingIcon className="w-12 h-12 text-customBeige-300" />
        </div>
      ) : (
        children
      )}
    </businessContext.Provider>
  );
}

function useBusinessProvider() {
  const { businesses, loading } = useMergeExtraData();
  return {
    businesses,
    loading,
  };
}

function useMergeExtraData() {
  const { data, status, error } = useBusinesses();
  const loading = status === "loading";
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const fetchImages = async () => {
        const enrichedBusinesses = await Promise.all(
          data.map(async (business) => {
            const { data: images, error: imagesError } = await supabase
              .storage
              .from("businesses")
              .list(`${business.id}/images`, {
                limit: 10, 
              })

            if (imagesError) {
              console.error("Error fetching images:", imagesError.message);
              return { ...business, images: [] };
            }

            const imageUrls = images
              .filter((img) => img.name !== "placeholder.jpg") 
              .map((img) =>
                `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/businesses/${business.id}/images/${img.name}`
              );

            return { ...business, images: imageUrls };
          })
        );

        setBusinesses(enrichedBusinesses);
      };

      fetchImages();
    }
  }, [data, status]);

  return { businesses, loading };
}
