import React, { useState, useEffect } from "react";
import SectionHeader from "./SectionHeader";
import { useAuth } from "../util/auth";
import { IconModal } from "./IconModal";
import CardModal from "./CardModal";
import { useBusiness } from "../util/business";
import { Link } from "react-router-dom";
import BusinessCardGrid from "./BusinessCardGrid";
import DashboardActions from "./DashboardActions";
import FormAlert from "./FormAlert";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import LoadingIcon from "./LoadingIcon";
import { SaveToApp } from "./SaveToApp"; 

function DashboardSection({ strapline }) {
  const [modalContent, setModalContent] = useState(null);
  const [cardModalContent, setCardModalContent] = useState(null);
  const [scanResult, setScanResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userNamePresent, setUserNamePresent] = useState(true);
  const [formAlert, setFormAlert] = useState(null);
  const [pending, setPending] = useState(false);

  const { handleSubmit, register, errors } = useForm();

  const auth = useAuth();
  const { businesses } = useBusiness();

  useEffect(() => {
    if (businesses.length > 0) {
      setLoading(false);
      setError(null);
    } else {
      setLoading(true);
    }
  }, [businesses]);

  const handleOpenIconModal = (content) => {
    setModalContent(content);
    setScanResult(null);
  };

  const handleOpenCardModal = (cardContent) => {
    setCardModalContent(cardContent);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setCardModalContent(null);
    setScanResult(null);
  };

  useEffect(() => {
    setUserNamePresent(!!auth?.user?.name?.trim());
  }, [auth.user]);

  const onSubmit = (data) => {
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => setFormAlert(null))
      .catch((error) => {
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => setPending(false));
  };

  if (!userNamePresent) {
    return (
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center p-10 sm:p-20">
          <div className="bg-white rounded-lg w-full sm:max-w-md max-w-lg">
            {formAlert && (
              <div className="mb-4">
                <FormAlert type={formAlert.type} message={formAlert.message} />
              </div>
            )}
            <label
              htmlFor="name"
              className="block text-lg text-center font-medium text-gray-700 mb-2"
            >
              What is your full name?
            </label>
            <TextField
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              error={errors.name}
              inputRef={register({ required: "Please enter your full name" })}
              className="w-full mb-4"
            />
            <button className="w-full py-3 bg-customBeige-300 text-white rounded-md text-center shadow-md hover:bg-customBeige-400 transition-all flex justify-center items-center">
              {!pending ? "Save" : <LoadingIcon className="w-6" />}
            </button>
          </div>
        </div>
      </form>
    );
  }

  return (
    <>
      <img
        className="w-full h-96 lg:w-96 lg:h-auto object-cover mx-auto"
        src="/CRESCENT_CONVENIENCE.png"
        alt="Crescent Convenience"
      />
      <div className="container text-center">
        <SectionHeader
          title={`Welcome ${auth?.user?.name?.split(" ")[0]} 👋`}
          subtitle="Visit one of the businesses below to claim your exclusive discount"
          strapline={strapline}
        />

        <DashboardActions
          auth={auth}
          handleOpenIconModal={handleOpenIconModal}
        />

      <SaveToApp />

        <BusinessCardGrid
          businessesArray={businesses}
          handleOpenCardModal={handleOpenCardModal}
          loading={loading}
          error={error}
        />
        {modalContent && (
          <IconModal
            modalContent={modalContent}
            handleCloseModal={handleCloseModal}
          />
        )}
        {cardModalContent && (
          <CardModal
            cardContent={cardModalContent}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div>
    </>
  );
}

export default DashboardSection;
