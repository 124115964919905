import React, { useState } from "react";
import { QRCodeSVG } from "qrcode.react"; // Import the QR code component
import { useAuth } from "./../util/auth";
import ModalScanner from "./ModalScanner"; // Import the scanner component
import AdminTool from "./AdminTool";

export const IconModal = ({
  modalContent,
  handleError,
  handleScan,
  scanResult,
  handleCloseModal,
}) => {
  const auth = useAuth(); // Access the auth object for the UUID
  const [error, setError] = useState(null);

  // const handleDownloadWalletPass = async () => {
  //   try {
  //     const response = await fetch("/api/generate-pass", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ uuid: auth.user.id }), // Include UUID in request body
  //     });

  //     if (response.ok) {
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = "business-pass.pkpass";
  //       link.click();
  //       window.URL.revokeObjectURL(url);
  //     } else {
  //       setError("Failed to download Apple Wallet pass. Please try again.");
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     setError("Failed to download Apple Wallet pass. Please try again.");
  //   }
  // };

  const renderQRCode = () => (
    <>
      <h2 className="text-2xl font-semibold text-gray-800 text-center mb-10">
        Present this QR Code at checkout to receive your exclusive discount.
      </h2>
      <div className="flex justify-center mb-6">
        <QRCodeSVG value={auth.user.id} size={200} />
      </div>
      <p className="text-lg text-gray-600 text-center mt-10">
        Simply scan the code to enjoy a special offer at your preferred location.
      </p>
    </>
  );
  

  const renderScanner = () => <ModalScanner handleError={handleError} />;

  const renderAdmin = () => <AdminTool />;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div
        className="relative w-full h-full md:w-auto md:h-auto md:max-w-lg rounded-lg overflow-hidden"
        style={{ background: "white" }}
      >
        <div className="w-full h-full flex flex-col p-6 sm:p-8 max-h-screen overflow-y-auto">
          {/* Render content based on modal type */}
          {modalContent === "QR Code" && renderQRCode()}
          {modalContent === "Scanner" && renderScanner()}
          {modalContent === "Admin" && renderAdmin()}

          {/* Display scan result if available */}
          {scanResult && (
            <div className="mt-4 text-lg text-blue-200">
              <p>Scan Result: {scanResult}</p>
            </div>
          )}

          {/* Content grows to fill available space */}
          <div className="flex-grow"></div>

          {/* Sticky button at the bottom of the modal */}
          <button
            onClick={handleCloseModal}
            className="mt-6 bg-customBeige px-4 py-2 rounded w-full sm:w-auto"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default IconModal;
