import React from "react";
import { QrCodeIcon, CameraIcon, ReceiptPercentIcon, CogIcon } from "@heroicons/react/24/solid";

function DashboardActions({ auth, handleOpenIconModal }) {
  const renderIcon = (Icon, label, modalContent) => (
    <div
      onClick={() => handleOpenIconModal(modalContent)}
      className="cursor-pointer flex flex-col items-center"
    >
      <Icon className="w-12 h-12 text-customBeige-300" />
      <p className="mt-2">{label}</p>
    </div>
  );

  return (
    <div className="flex flex-row items-center justify-center space-x-6 py-8">
      {!auth?.user?.isBusiness && renderIcon(QrCodeIcon, "QR Code", "QR Code")}

      {auth.user.isBusiness && (
        <>
          {renderIcon(CameraIcon, "Scanner", "Scanner")}
          
          {auth?.user?.businesses[0]?.rate === "variable" &&
            renderIcon(ReceiptPercentIcon, "Transactions", "Receipt")}
        </>
      )}

      {auth.user.role === "superadmin" && renderIcon(CogIcon, "Admin", "Admin")}
    </div>
  );
}

export default DashboardActions;
