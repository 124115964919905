import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        title="Join the Family: exclusive discounts at locally owned businesses for absolutley nothing! 🌙"
        subtitle="Unlock access to exclusive deals and discounts from local businesses businesses. No hidden fees, no complicated plans—just great value"
        strapline="Limited time offer!"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default PricingPage;
