import React from "react";

function BusinessCard({ card, handleOpenCardModal }) {
  const containedImages = [
    "Harrys MOTs",
    "Umbrella Plumbing and Heating",
    "SCREEDMEISTER",
    "Khoyaz"
  ];

  const isCardContained = containedImages.find((name) => card.name === name);

  const faceCardUrl = card?.images.find((imageUrl) =>
    imageUrl.includes("face.png")
  );

  return (
    <div
      onClick={() => handleOpenCardModal(card)}
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
    >
      <img
        src={faceCardUrl || null}
        alt={card.name}
        className={`w-full h-48 ${
          isCardContained ? "object-contain" : "object-cover"
        }`}
      />

      <div className="p-6">
        {card?.discount_description && (
          <div
            className={`p-1 mb-1 rounded-md ${
              card?.discount_description === "Coming soon!"
                ? "bg-gray-200"
                : "bg-customBeige"
            }`}
          >
            {card?.discount_description}
          </div>
        )}
        <h3 className="text-xl font-bold mb-2">{card.name}</h3>
        <p className="text-gray-700">
          {card.short_description || card.description}
        </p>
      </div>
    </div>
  );
}

export default BusinessCard;
