import React, { useEffect, useState } from 'react';
import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import supabase from "./supabase";
import { useAuth } from "./auth"

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that fetches data
    () =>
      supabase
        .from("users")
        .select(`*, customers ( * )`)
        .eq("id", uid)
        .single()
        .then(handle),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

export function useBusinesses() {
  const [supabaseReady, setSupabaseReady] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (supabase) {
      setSupabaseReady(true);
    }
  }, []);

  return useQuery(
    ["businesses"],
    async () => {
      const { data, error } = await supabase
        .from("businesses")
        .select("*")
        .eq("isVisible", true);

      // Handle any errors that occur during the query
      if (error) {
        console.error("Error fetching businesses:", error.message);
        return [];
      }

      // Return the fetched data or empty array if data is empty
      return data || [];
    },
    {
      enabled: supabaseReady && !!user,
      refetchInterval: (data) => {
        // Poll if the data array is empty (you can adjust the interval time)
        if (Array.isArray(data) && data.length === 0) {
          return 2000; // Poll every 5 seconds if data is empty
        }
        return false; // Disable polling if data is not empty
      },
    }
  );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return supabase
    .from("users")
    .select(`*, customers ( * )`)
    .eq("id", uid)
    .single()
    .then(handle);
}

// Update an existing user
export async function updateUser(uid, data) {
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", uid)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", { uid }]);
  return response;
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// db.js
export async function getBusinessesByUser(uid) {
  try {
    const { data, error } = await supabase
      .from('businesses') // Replace with the actual name of your businesses table
      .select('*')
      .eq('owner', uid); // Assuming 'owner' is the foreign key for the user

    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    console.error('Error fetching businesses:', error);
    return null;
  }
}


export async function checkIfSubscribed(uid) {
  try {
    const { data, error } = await supabase
      .from("customers")
      .select('*')
      .eq("id", '8da5f2ce-8c30-4543-ae17-509c6dc35417')  // Using dynamic UID
      .eq('stripeSubscriptionStatus', 'active');

    if (error) {
      console.error('Supabase Error:', error.message);
      return null; // Handle error
    }

    // Invalidate cache for 'user' query with uid, if caching is involved
    await client.invalidateQueries(["user", { uid }]);
    
    // Optionally refetch to ensure fresh data
    await client.refetchQueries(["user", { uid }]);

    return data; // Return data if all goes well
  } catch (err) {
    console.error("Error fetching subscription status:", err);
    return null; // Handle error gracefully
  }
}

// Fetch discounts data
export function useItem(id) {
  return useQuery(
    ["discount", { id }],
    () => supabase.from("discounts").select().eq("id", id).single().then(handle),
    { enabled: !!id }
  );
}

// Fetch all discounts by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["discounts", { owner }],
    () =>
      supabase
        .from("discounts")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner }
  );
}

export async function recordTransaction(uid, businessId, cost, currency, customer, valueAfterDiscount, featured = null) {
  try {
    // Insert a transaction record into the 'discounts' table
    const { data, error } = await supabase
      .from("discounts")
      .insert([
        {
          owner: uid,
          name: null,
          featured: featured,
          createdAt: new Date().toISOString(),
          business: businessId,
          value: cost, // Original transaction cost
          value_after_discount: valueAfterDiscount, // Discounted cost
          currency: currency,
          customer: customer
        },
      ])
      .select('*');

    if (error) {
      throw new Error(error.message);
    }

    return data; // Return the transaction data if successful
  } catch (error) {
    console.error("Error recording transaction:", error);
    throw error; // Rethrow the error to handle it elsewhere
  }
}


// Create a new item
export async function createItem(data) {
  const response = await supabase.from("discounts").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["discounts"]);
  return response;
}

// Update an item
export async function updateItem(id, data) {
  const response = await supabase
    .from("discounts")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["discount", { id }]),
    client.invalidateQueries(["discounts"]),
  ]);
  return response;
}

// Delete an item
export async function deleteItem(id) {
  const response = await supabase
    .from("discounts")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["discount", { id }]),
    client.invalidateQueries(["discounts"]),
  ]);
  return response;
}

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response) {
  if (response.error) throw response.error;
  return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
