import React from "react";
import { BookmarkIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

function PricingSection(props) {
  const auth = useAuth();

  const plan = {
    id: "pro",
    name: "Special offer, won't last long!",
    price: "FREE",
    subtitle: "",
    featured: true,
    perks: [
      <>
        <strong>Exclusive</strong> Discounts on Local Products
      </>,
      <>
        <strong>Priority</strong> Access to New Deals
      </>,
      <>
        <strong>Community</strong> of Like-Minded Shoppers (coming soon!)
      </>,
      <>
        <strong>Personalised</strong> Recommendations Based on Your Interests
        (also coming soon!)
      </>,
    ],
  };

  return (
    <div className="container mx-auto space-y-10 px-4 pb-10">
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        strapline={props.strapline}
        className="text-center"
      />
      <div className="flex justify-center">
        <div
          className={
            "rounded-lg shadow-sm flex flex-col border-customBeige" +
            (plan.featured
              ? " bg-blue-50 lg:border-4 border-blue-300 hover:border-customBeige-200 relative lg:-mx-2 lg:-my-6"
              : "")
          }
          style={{ width: "100%", maxWidth: "400px" }}
        >
          <div className="p-5 lg:p-6 text-center bg-white rounded-t-lg">
            <span className="inline-block text-sm uppercase tracking-wider font-semibold px-3 py-1 bg-customBeige bg-opacity-50 rounded-full mb-4">
              {plan.name}
            </span>
            <div className="mb-1">
              <span className="text-3xl lg:text-4xl font-extrabold">
                {plan.price}
              </span>
              {/* <span className="text-gray-700 font-semibold">/year</span> */}
              <p className="text-gray-600 text-sm font-medium">
                {plan.subtitle}
              </p>
            </div>
          </div>

          <div
            className={
              "p-5 lg:p-6 space-y-5 lg:space-y-6 text-gray-700 grow" +
              (plan.featured ? " text-blue-900" : "")
            }
          >
            <ul className="space-y-4 text-sm lg:text-base">
              {plan.perks.map((perk, index) => (
                <li className="flex items-start space-x-2" key={index}>
                  <CheckCircleIcon className="text-emerald-500 flex-shrink-0 w-5 h-5" /> {/* Fixed size and prevent shrinking */}
                  <span>{perk}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="px-5 pb-5 lg:px-6 lg:pb-6">
            <Button
              component={Link}
              to={
                auth.user
                  ? `/purchase/${plan.id}`
                  : `/auth/signup?next=/purchase/${plan.id}`
              }
              size="lg"
              variant="primary"
              isBlock={true}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingSection;
