import React from "react";
import parse from 'html-react-parser';

function LegalTerms(props) {

  const htmlContent = `
  <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=unicode">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Aptos;
	panose-1:2 11 0 4 2 2 2 2 2 4;}
@font-face
	{font-family:"DengXian Light";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"\@DengXian Light";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:36.0pt;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Aptos",sans-serif;}
.MsoChpDefault
	{font-size:10.0pt;
	font-family:"Aptos",sans-serif;}
@page WordSection1
	{size:595.0pt 842.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=EN-GB style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-size:10.0pt;line-height:115%'>MSLM LIMITED (TRADING AS CRESCENT
CONVENIENCE TERMS AND CONDITIONS)</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-size:10.0pt;line-height:115%'>Please read all these Terms and
Conditions.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-size:10.0pt;line-height:115%'>As we can accept your order and make a
legally enforceable agreement without further reference to you, you must read
these Terms and Conditions to make sure that they contain all that you want and
nothing that you are not happy with. </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Application</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>These Terms and
Conditions will apply to the purchase of the services and goods by you (the
Customer or you). We are MSLM LIMITED whose trading name is Crescent
Convenience a company registered in England and Wales under number 15951867
whose registered office is at 14 Somerville Way, Aylesbury,  HP19 7QQ   with 
email address <span>contact@crescentconvenience.com</span>; 
(the Supplier or us or we).</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>These are the
terms on which we provide all our Services to you.  When registering for the
website or using our platform, you will be asked to agree to these Terms and
Conditions by clicking on the button marked 'I Accept'. If you do not click on
the button, you will not be able to use our Service.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Interpretation</span></b></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Affiliates</span></b><span
style='font-size:10.0pt;line-height:115%'> means any of our affiliates who
provide offers to Crescent Convenience users;</span></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Consumer</span></b><span
style='font-size:10.0pt;line-height:115%'> means an individual acting for
purposes which are wholly or mainly outside their trade, business, craft or
profession;</span></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Contract</span></b><span
style='font-size:10.0pt;line-height:115%'> means the legally-binding agreement
between you and us for the supply of the Services;</span></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Goods</span></b><span
style='font-size:10.0pt;line-height:115%'> means any goods that you purchase
from our Affiliates;</span></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Offers</span></b><span
style='font-size:10.0pt;line-height:115%'> means the offers for Goods and
Services that are provided by our Affiliates;</span></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Platform</span></b><span
style='font-size:10.0pt;line-height:115%'> means the Crescent Convenience
platform (including our app or our Website) on which our Offers are advertised.</span></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Privacy
Policy</span></b><span style='font-size:10.0pt;line-height:115%'> means the
terms which set out how we will deal with confidential and personal information
received from you via the Platform;</span></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Services</span></b><span
style='font-size:10.0pt;line-height:115%'> means any services you purchase from
our Affiliates;</span></p>

<p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-size:10.0pt;line-height:115%'>Website</span></b><span
style='font-size:10.0pt;line-height:115%'> means our website <span>www.crescentconvenience.com.</span></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Offers</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>Offers for any
Services and any Goods from our Affiliates is as set out in the Website,
catalogues, brochures or other form of advertisement. Any description is for
illustrative purposes only it is up to you when purchasing the Goods and
Services from our Affiliates to confirm that they are suitable for your needs.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>We can make
changes to the Offers which are necessary to comply with any applicable law or
safety requirement. We will notify you of these changes on our Platform.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>4)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Customer
Responsibilities</span></b></p>

<p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:36.0pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-size:10.0pt;line-height:115%'>You acknowledge and agree that you
are not purchasing any Goods or Services from us, your transaction will be with
the relevant Affiliate from whom you have purchased the Goods or Services from,
any purchases you make will be subject to their terms and conditions and MSLM
Limited is not responsible for any purchases from our Affiliates.</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>5)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Personal
Information and Registration</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>When registering
to use the Platform you must set up a username and password. You remain
responsible for all actions taken under the chosen username and password and
undertake not to disclose your username and password to anyone else and keep
them secret.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>We retain and use
all information strictly under the Privacy Policy.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>We may contact
you by using e-mail or other electronic communication methods and by pre-paid
post and you expressly agree to this.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>6)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Basis of
Sale</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>The description
of the Services and any Goods in our website does not constitute a contractual
offer to sell the Services or Goods. You must make the order directly with the
Affiliate and the order will be subject to our Affiliate’s own terms and
conditions.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>The process for
how to redeem an offer is set out on the Platform. You will be required to
present your QR code to redeem an Offer at any of our Affiliates</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>We intend that
these Terms and Conditions apply only to your use of the Platform.  </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>7)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Circumstances
Beyond the Control of Either Party</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>In the event of
any failure by a party because of something beyond its reasonable control:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:54.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:16.9pt'><span style='font-size:10.0pt;line-height:115%'>i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>the party will
advise the other party as soon as reasonably practicable; and</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:106.35pt;text-align:
justify;text-justify:inter-ideograph;text-indent:-35.45pt'><span
style='font-size:10.0pt;line-height:115%'>ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>the party's
obligations will be suspended so far as is reasonable, provided that that party
will act reasonably, and the party will not be liable for any failure which it
could not reasonably avoid, but this will not affect the Customer's above
rights relating to delivery (and the right to cancel below).</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>8)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Privacy</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>Your privacy is
critical to us. We respect your privacy and comply with the General Data
Protection Regulation with regard to your personal information.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>These Terms and
Conditions should be read alongside, and are in addition to our policies,
including our Privacy Policy (<span>www.crescentconvenience.com/legal/privacy-policy</span>) and Cookies Policy <span>(<span>www.crescentconvenience.com/legal/privacy-policy</span>).</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>For the purposes
of these Terms and Conditions:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>'Data Protection
Laws' means any applicable law relating to the processing of Personal Data,
including, but not limited to the GDPR.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:54.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:16.9pt'><span style='font-size:10.0pt;line-height:115%'>ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>'GDPR' means the
UK General Data Protection Regulation.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>'Data
Controller', 'Personal Data' and 'Processing' shall have the same meaning as in
the GDPR.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>We are a Data
Controller of the Personal Data we Process in providing the Offers to you.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>Where you supply
Personal Data to us so we can provide Offers to you, and we Process that
Personal Data in the course of providing the Offers to you, we will comply with
our obligations imposed by the Data Protection Laws:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>before or at the
time of collecting Personal Data, we will identify the purposes for which
information is being collected;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>we will only
Process Personal Data for the purposes identified;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>we will respect
your rights in relation to your Personal Data; and</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>iv)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>we will implement
technical and organisational measures to ensure your Personal Data is secure.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>For any enquiries
or complaints regarding data privacy, you can e-mail: <span>contact@crescentconvenience.com</span>.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>9)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Excluding
Liability</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>We do not exclude
liability for: </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>any fraudulent
act or omission; or </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>death or personal
injury caused by negligence or breach of our other legal obligations. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>Subject to clause
9 (a) above, we are not liable for:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>loss which was
not reasonably foreseeable to both parties at the time when the Contract was
made; or </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:106.35pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>loss (eg loss of
profit) to your business, trade, craft or profession which would not be
suffered by a Consumer - because we believe you are not buying the Services and
Goods from our Affiliates wholly or mainly for your business, trade, craft or
profession.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>10)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Governing
Law, Jurisdiction and Complaints</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>The Contract
(including any non-contractual matters) is governed by the law of England and
Wales.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>Disputes can be
submitted to the jurisdiction of the courts of England and Wales or, where the
Customer lives in Scotland or Northern Ireland, in the courts of respectively
Scotland or Northern Ireland.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>We try to avoid
any dispute, so we deal with complaints as follows: If a dispute occurs
customers should contact us to find a solution. We will aim to respond with an
appropriate solution within 5 days.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:18.0pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><b><span style='font-size:
10.0pt;line-height:115%'>11)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='font-size:10.0pt;line-height:115%'>Variation</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>We reserve the
right to amend these Terms and Conditions at any time, at our sole discretion.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>Any amendments
will be communicated to you through the Platform. It is your responsibility to
review any changes to these Terms and Conditions.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>Your continued
use of the Platform following any amendments constitutes your acceptance of the
revised Terms and Conditions.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:70.9pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-35.45pt'><span style='font-size:10.0pt;line-height:115%'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:115%'>If you do not
agree to the amended Terms and Conditions, you must cease using the Platform
immediately</span></p>

</div>

</body>

</html>
  `
  return <div>{parse(htmlContent)}</div>;

}

export default LegalTerms;
