import React from "react";
import BusinessCard from "./BusinessCard";
import LoadingIcon from "./LoadingIcon";

const  BusinessCardGrid = ({ businessesArray, handleOpenCardModal, loading, error }) => {
  const sortedBusinessByAlphabet = businessesArray?.sort((a, b) => a.name.localeCompare(b.name));

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <LoadingIcon className="w-12 h-12 text-customBeige-300" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500">
        {error}
        <button
          onClick={() => window.location.reload()}
          className="ml-4 px-4 py-2 bg-red-600 text-white rounded-md"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 py-8">
      {sortedBusinessByAlphabet.map((card, index) => (
        <BusinessCard key={index} card={card} handleOpenCardModal={handleOpenCardModal} />
      ))}
    </div>
  );
}

export default BusinessCardGrid;
