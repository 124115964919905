import React from "react";
import parse from 'html-react-parser';

function LegalPrivacy(props) {
  const htmlContent = `
  <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Calibri Light";
	panose-1:2 15 3 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	text-align:center;
	page-break-after:avoid;
	font-size:18.0pt;
	font-family:"Times New Roman",serif;
	color:#2F5496;
	text-transform:uppercase;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Calibri Light",sans-serif;
	color:#2F5496;}
p.htmlGeneratedanynoth1, li.htmlGeneratedanynoth1, div.htmlGeneratedanynoth1
	{mso-style-name:"htmlGenerated_any_not\(h1\)";
	margin:0cm;
	line-height:12.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
span.htmlGeneratedanynoth1Character
	{mso-style-name:"htmlGenerated_any_not\(h1\) Character";}
p.olclausesli, li.olclausesli, div.olclausesli
	{mso-style-name:ol_clauses_li;
	margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.olclausesliolli, li.olclausesliolli, div.olclausesliolli
	{mso-style-name:"ol_clauses > li > ol > li";
	margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-size:10.0pt;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:10.65pt 10.65pt 10.65pt 10.65pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=EN-GB link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<h1 style='margin-top:18.0pt;margin-right:35.35pt;margin-bottom:36.0pt;
margin-left:35.45pt;page-break-after:auto'><span lang=EN-US style='color:windowtext'>PRIVACY
POLICY</span></h1>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>This Privacy Policy applies between you, the User of
this Website, and&nbsp;<span class=htmlGeneratedanynoth1Character>MSLM LIMITED
(trading as Crescent Convenience)</span>, the owner and provider of this
Website.&nbsp;<span class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading
as Crescent Convenience)</span>&nbsp;takes the privacy of your information very
seriously. This Privacy Policy applies to our use of any and all Data collected
by us or provided by you in relation to your use of the Website.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>This Privacy Policy should be read alongside, and in
addition to, our Terms and Conditions, which can be found at:&nbsp;<span
class=htmlGeneratedanynoth1Character>www.crescentconvenience.com/legal/terms-of-service</span></span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:10.5pt'>Please
read this Privacy Policy carefully</span></b></span><span lang=EN-US
style='font-size:10.5pt'>.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Definitions and Interpretation</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>1. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>In this Privacy Policy,
the following definitions are used:<br>
<br>
</span></p>

<table class=htmlGeneratedanynoth1Table border=1 cellspacing=0 cellpadding=0
 style='margin-left:22.5pt;border-collapse:collapse;border:none'>
 <tr>
  <td valign=top style='border:solid black 1.0pt;background:#EEEEEE;padding:
  3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:
  10.5pt;color:black'>Data</span></b></span></p>
  </td>
  <td width=419 valign=top style='width:314.0pt;border:solid black 1.0pt;
  border-left:none;background:#EEEEEE;padding:3.75pt 4.1pt 3.75pt 4.0pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>collectively all information
  that you submit to&nbsp;<span class=htmlGeneratedanynoth1Character>MSLM
  LIMITED (trading as Crescent Convenience)</span>&nbsp;via the Website. This
  definition incorporates, where applicable, the definitions provided in the
  Data Protection Laws;</span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:
  10.5pt;color:black'>Cookies</span></b></span></p>
  </td>
  <td width=419 valign=top style='width:314.0pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:3.75pt 4.1pt 3.75pt 4.0pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>a small text file placed on
  your computer by this Website when you visit certain parts of the Website and/or
  when you use certain features of the Website. Details of the cookies used by
  this Website are set out in the clause below (<span
  class=htmlGeneratedanynoth1Character><b>Cookies</b></span>);</span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;background:
  #EEEEEE;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:
  10.5pt;color:black'>Data Protection Laws</span></b></span></p>
  </td>
  <td width=419 valign=top style='width:314.0pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  background:#EEEEEE;padding:3.75pt 4.1pt 3.75pt 4.0pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  class=htmlGeneratedanynoth1Character><span lang=EN-US style='font-size:10.5pt;
  color:black'>any applicable law relating to the processing of personal Data,
  including but not limited to the&nbsp;GDPR, and any national implementing and
  supplementary laws, regulations and secondary legislation;</span></span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:
  10.5pt;color:black'>GDPR</span></b></span></p>
  </td>
  <td width=419 valign=top style='width:314.0pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:3.75pt 4.1pt 3.75pt 4.0pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  class=htmlGeneratedanynoth1Character><span lang=EN-US style='font-size:10.5pt;
  color:black'>the UK General Data Protection Regulation;</span></span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;background:
  #EEEEEE;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:
  10.5pt;color:black'>MSLM LIMITED (trading as Crescent Convenience),&nbsp;<span
  style='display:none'>&nbsp;<br>
  &nbsp;</span>we</span></b></span><span lang=EN-US style='font-size:10.5pt;
  color:black'>&nbsp;or&nbsp;<span class=htmlGeneratedanynoth1Character><b>us</b></span></span></p>
  </td>
  <td width=419 valign=top style='width:314.0pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  background:#EEEEEE;padding:3.75pt 4.1pt 3.75pt 4.0pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>&nbsp;<span
  class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading as Crescent
  Convenience), a company incorporated in&nbsp;England and Wales&nbsp;with
  registered number&nbsp;15951867&nbsp;whose registered office is at&nbsp;14
  Somerville Way,&nbsp;Aylesbury,&nbsp;HP19 7QQ;&nbsp;</span></span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:
  10.5pt;color:black'>UK and EU Cookie Law</span></b></span></p>
  </td>
  <td width=419 valign=top style='width:314.0pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:3.75pt 4.1pt 3.75pt 4.0pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>the Privacy and Electronic
  Communications (EC Directive) Regulations 2003 as amended by the Privacy and
  Electronic Communications (EC Directive) (Amendment) Regulations 2011 &amp;
  the Privacy and Electronic Communications (EC Directive) (Amendment)
  Regulations 2018;</span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;background:
  #EEEEEE;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:
  10.5pt;color:black'>User</span></b></span><span lang=EN-US style='font-size:
  10.5pt;color:black'>&nbsp;or&nbsp;<span class=htmlGeneratedanynoth1Character><b>you</b></span></span></p>
  </td>
  <td width=419 valign=top style='width:314.0pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  background:#EEEEEE;padding:3.75pt 4.1pt 3.75pt 4.0pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>any third party that accesses
  the Website and is not either (i) employed by&nbsp;<span
  class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading as Crescent
  Convenience)</span>&nbsp;and acting in the course of their employment or (ii)
  engaged as a consultant or otherwise providing services to&nbsp;<span
  class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading as Crescent
  Convenience)</span>&nbsp;and accessing the Website in connection with the
  provision of such services; and</span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:
  10.5pt;color:black'>Website</span></b></span></p>
  </td>
  <td width=419 valign=top style='width:314.0pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:3.75pt 4.1pt 3.75pt 4.0pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>the website that you are
  currently using, www.crescentconvenience.com and any sub-domains of this site
  unless expressly excluded by their own terms and conditions.</span></p>
  </td>
 </tr>
</table>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>2. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>In this Privacy Policy,
unless the context requires a different interpretation:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>the singular includes
the plural and vice versa;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>references to
sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses,
schedules or appendices of this Privacy Policy;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>a reference to a person
includes firms, companies, government entities, trusts and partnerships;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>&quot;including&quot;
is understood to mean &quot;including without limitation&quot;;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>reference to any
statutory provision includes any modification or amendment of it;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>f.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>the headings and
sub-headings do not form part of this Privacy Policy.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Scope of this Privacy Policy</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>3. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>This Privacy Policy
applies only to the actions of&nbsp;<span class=htmlGeneratedanynoth1Character>MSLM
LIMITED (trading as Crescent Convenience)</span>&nbsp;and Users with respect to
this Website. It does not extend to any websites that can be accessed from this
Website including, but not limited to, any links we may provide to social media
websites.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>4. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>For purposes of the
applicable Data Protection Laws,&nbsp;<span
class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading as Crescent
Convenience)</span>&nbsp;is the &quot;data controller&quot;. This means
that&nbsp;<span class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading as
Crescent Convenience)</span>&nbsp;determines the purposes for which, and the
manner in which, your Data is processed.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Data Collected</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>5. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>We may collect the
following Data, which includes personal Data, from you:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>name;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>date of birth;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>gender;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>contact Information
such as email addresses and telephone numbers;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>web browser type and
version (automatically collected);</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:10.5pt;margin-left:35.45pt'><span lang=EN-US style='font-size:
10.5pt'>in each case, in accordance with this Privacy Policy.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>How We Collect Data</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>6. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>We collect Data in the
following ways:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>data is given to us by
you<span class=htmlGeneratedanynoth1Character>;</span></span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>data is received from
other sources; and</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>data is collected
automatically.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Data That is Given to Us by You</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>7. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><span lang=EN-US
style='font-size:10.5pt'>We</span></span><span lang=EN-US style='font-size:
10.5pt'>&nbsp;will collect your Data in a number of ways, for example:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>when you contact us through
the Website, by telephone, post, e-mail or through any other means;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>when you register with
us and set up an account to receive our products/services;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>when you enter a
competition or promotion through a social media channel;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>when you elect to
receive marketing communications from us;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>when you use our
services;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:10.5pt;margin-left:35.45pt'><span lang=EN-US style='font-size:
10.5pt'>in each case, in accordance with this Privacy Policy.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Data That is Received From Third Parties</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>8. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><span lang=EN-US
style='font-size:10.5pt'>We</span></span><span lang=EN-US style='font-size:
10.5pt'>&nbsp;will receive Data about you from the following third parties:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><span lang=EN-US
style='font-size:10.5pt'>our affiliate partners where offers can be redeemed</span></span><span
lang=EN-US style='font-size:10.5pt'>.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Data That is Collected Automatically</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>9. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>To the extent that you
access the Website, we will collect your Data automatically, for example:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>we automatically
collect some information about your visit to the Website. This information
helps us to make improvements to Website content and navigation, and includes
your IP address, the date, times and frequency with which you access the
Website and the way you use and interact with its content.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>we will collect your
Data automatically via cookies, in line with the cookie settings on your
browser. For more information about cookies, and how we use them on the
Website, see the section below, headed &quot;Cookies&quot;.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Our Use of Data</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>10. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>Any or all of the above
Data may be required by us from time to time in order to provide you with the
best possible service and experience when using our Website. Specifically, Data
may be used by us for the following reasons:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>internal record
keeping;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>improvement of our
products / services;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>transmission by email
of marketing materials that may be of interest to you;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><span lang=EN-US
style='font-size:10.5pt'>to redeem your offers with our affiliates</span></span><span
lang=EN-US style='font-size:10.5pt'>;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt'><span lang=EN-US style='font-size:
10.5pt'>in each case, in accordance with this Privacy Policy.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>11. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>We may use your Data
for the above purposes if we deem it necessary to do so for our legitimate
interests. If you are not satisfied with this, you have the right to object in
certain circumstances (see the section headed &quot;Your rights&quot; below).</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>12. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>For the delivery of
direct marketing to you via e-mail, we'll need your consent, whether via an
opt-in or soft-opt-in:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>soft opt-in consent is
a specific type of consent which applies when you have previously engaged with
us (for example, you contact us to ask us for more details about a particular
product/service, and we are marketing similar products/services). Under &quot;soft
opt-in&quot; consent, we will take your consent as given unless you opt-out.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>for other types of
e-marketing, we are required to obtain your explicit consent; that is, you need
to take positive and affirmative action when consenting by, for example,
checking a tick box that we'll provide.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>if you are not
satisfied with our approach to marketing, you have the right to withdraw
consent at any time. To find out how to withdraw your consent, see the section
headed &quot;Your rights&quot; below.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>13. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>When you register with
us and set up an account to receive our services, the legal basis for this
processing is the performance of a contract between you and us and/or taking
steps, at your request, to enter into such a contract.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>14. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>We may use your Data to
show you&nbsp;<span class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading
as Crescent Convenience)</span>&nbsp;adverts and other content on other
websites. If you do not want us to use your data to show you&nbsp;<span
class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading as Crescent
Convenience)</span>&nbsp;adverts and other content on other websites, please
turn off the relevant cookies (please refer to the section headed
&quot;Cookies&quot; below).</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Who We Share Data With</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>15. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>We may share your Data
with the following groups of people for the following reasons:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>any of our group
companies or affiliates -&nbsp;<span class=htmlGeneratedanynoth1Character>to
confirm your offer and record your transaction</span>;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>our employees, agents
and/or professional advisors -&nbsp;<span class=htmlGeneratedanynoth1Character>to
obtain advice from professional advisers</span>;</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>third party service
providers who provide services to us which require the processing of personal
data -&nbsp;<span class=htmlGeneratedanynoth1Character>to help third party
service providers in receipt of any shared data to perform functions on our
behalf including to confirm and redeem your offers</span>;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:10.5pt;margin-left:35.45pt'><span lang=EN-US style='font-size:
10.5pt'>in each case, in accordance with this Privacy Policy.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Keeping Data Secure</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>16. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>We will use technical
and organisational measures to safeguard your Data, for example:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>access to your account
is controlled by a password and a user name that is unique to you.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>we store your Data on
secure servers.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>17. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>Technical and
organisational measures include measures to deal with any suspected data
breach. If you suspect any misuse or loss or unauthorised access to your Data,
please let us know immediately by contacting us via this e-mail address:&nbsp;<span
class=htmlGeneratedanynoth1Character>contact@crescentconvenience.com</span>.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>18. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>If you want detailed
information from Get Safe Online on how to protect your information and your
computers and devices against fraud, identity theft, viruses and many other
online problems, please visit www.getsafeonline.org. Get Safe Online is
supported by HM Government and leading businesses.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Data Retention</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>19. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>Unless a longer
retention period is required or permitted by law, we will only hold your Data
on our systems for the period necessary to fulfil the purposes outlined in this
Privacy Policy or until you request that the Data be deleted.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>20. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>Even if we delete your
Data, it may persist on backup or archival media for legal, tax or regulatory
purposes.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Your Rights</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>21. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>You have the following
rights in relation to your Data:</span></p>

<p class=olclausesliolli style='margin-top:5.25pt;margin-right:35.35pt;
margin-bottom:5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><b><span lang=EN-US
style='font-size:10.5pt'>Right to access</span></b></span><span lang=EN-US
style='font-size:10.5pt'>&nbsp;- the right to request (i) copies of the
information we hold about you at any time, or (ii) that we modify, update or
delete such information. If we provide you with access to the information we
hold about you, we will not charge you for this, unless your request is
&quot;manifestly unfounded or excessive.&quot; Where we are legally permitted
to do so, we may refuse your request. If we refuse your request, we will tell
you the reasons why.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><b><span lang=EN-US
style='font-size:10.5pt'>Right to correct</span></b></span><span lang=EN-US
style='font-size:10.5pt'>&nbsp;- the right to have your Data rectified if it is
inaccurate or incomplete.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><b><span lang=EN-US
style='font-size:10.5pt'>Right to erase</span></b></span><span lang=EN-US
style='font-size:10.5pt'>&nbsp;- the right to request that we delete or remove
your Data from our systems.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><b><span lang=EN-US
style='font-size:10.5pt'>Right to restrict our use of your Data</span></b></span><span
lang=EN-US style='font-size:10.5pt'>&nbsp;- the right to &quot;block&quot; us
from using your Data or limit the way in which we can use it.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><b><span lang=EN-US
style='font-size:10.5pt'>Right to data portability</span></b></span><span
lang=EN-US style='font-size:10.5pt'>&nbsp;- the right to request that we move,
copy or transfer your Data.</span></p>

<p class=olclausesliolli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>f.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><b><span lang=EN-US
style='font-size:10.5pt'>Right to object</span></b></span><span lang=EN-US
style='font-size:10.5pt'>&nbsp;- the right to object to our use of your Data
including where we use it for our legitimate interests.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>22. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>To make enquiries,
exercise any of your rights set out above, or withdraw your consent to the
processing of your Data (where consent is our legal basis for processing your
Data), please contact us via this e-mail address:&nbsp;<span
class=htmlGeneratedanynoth1Character>contact@crescentconvenience.com</span></span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>23. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>If you are not
satisfied with the way a complaint you make in relation to your Data is handled
by us, you may be able to refer your complaint to the relevant data protection
authority. For the UK, this is the Information Commissioner's Office (ICO). The
ICO's contact details can be found on their website at https://ico.org.uk/.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>24. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>It is important that
the Data we hold about you is accurate and current. Please keep us informed if
your Data changes during the period for which we hold it.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Links to Other Websites</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>25. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>This Website may, from
time to time, provide links to other websites. We have no control over such websites
and are not responsible for the content of these websites. This Privacy Policy
does not extend to your use of such websites. You are advised to read the
Privacy Policy or statement of other websites prior to using them.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Changes of Business Ownership and Control</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>26. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><span lang=EN-US
style='font-size:10.5pt'>MSLM LIMITED (trading as Crescent Convenience)</span></span><span
lang=EN-US style='font-size:10.5pt'>&nbsp;may, from time to time, expand or
reduce our business and this may involve the sale and/or the transfer of
control of all or part of&nbsp;<span class=htmlGeneratedanynoth1Character>MSLM
LIMITED (trading as Crescent Convenience)</span>. Data provided by Users will,
where it is relevant to any part of our business so transferred, be transferred
along with that part and the new owner or newly controlling party will, under
the terms of this Privacy Policy, be permitted to use the Data for the purposes
for which it was originally supplied to us.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>27. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>We may also disclose
Data to a prospective purchaser of our business or any part of it.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>28. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>In the above instances,
we will take steps with the aim of ensuring your privacy is protected.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Cookies</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>29. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>This Website may place
and access certain Cookies on your computer.&nbsp;<span
class=htmlGeneratedanynoth1Character>&nbsp;We&nbsp;use Cookies to improve your
experience of using the Website.  We</span>&nbsp;have carefully chosen these
Cookies and has taken steps to ensure that your privacy is protected and
respected at all times.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>30. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>All Cookies used by
this Website are used in accordance with current UK and EU Cookie Law.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>31. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>Before the Website
places Cookies on your computer, you will be presented with a message bar
requesting your consent to set those Cookies. By giving your consent to the
placing of Cookies, you are enabling&nbsp;<span
class=htmlGeneratedanynoth1Character>MSLM LIMITED</span>&nbsp;to provide a
better experience and service to you. You may, if you wish, deny consent to the
placing of Cookies; however certain features of the Website may not function
fully or as intended.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>32. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>This Website may place
the following Cookies:</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<table class=htmlGeneratedanynoth1Table border=0 cellspacing=0 cellpadding=0
 width="92%" style='width:92.46%;margin-left:22.5pt;border-collapse:collapse'>
 <tr>
  <td width="54%" style='width:54.06%;border:solid black 1.0pt;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Type of Cookie</span></b></span></p>
  </td>
  <td width="45%" style='width:45.94%;border:solid black 1.0pt;border-left:
  none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Purpose</span></b></span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>Strictly necessary cookies</span></p>
  </td>
  <td width="45%" valign=top style='width:45.94%;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>These are cookies that are
  required for the operation of our website. They include, for example, cookies
  that enable you to log into secure areas of our website, use a shopping cart
  or make use of e-billing services.</span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>Analytical/performance
  cookies</span></p>
  </td>
  <td width="45%" valign=top style='width:45.94%;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>They allow us to recognise
  and count the number of visitors and to see how visitors move around our
  website when they are using it. This helps us to improve the way our website
  works, for example, by ensuring that users are finding what they are looking for
  easily.</span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>Functionality cookies</span></p>
  </td>
  <td width="45%" valign=top style='width:45.94%;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>These are used to recognise
  you when you return to our website. This enables us to personalise our
  content for you, greet you by name and remember your preferences (for
  example, your choice of language or region). By using the Website, you agree
  to our placement of functionality cookie.</span></p>
  </td>
 </tr>
 <tr>
  <td valign=top style='border:solid black 1.0pt;border-top:none;padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>Targeting cookies</span></p>
  </td>
  <td width="45%" valign=top style='width:45.94%;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:3.75pt 4.1pt 3.75pt 4.1pt'>
  <p class=MsoNormal style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
  0cm;margin-left:35.45pt;margin-bottom:.0001pt;line-height:12.0pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>These cookies record your
  visit to our website, the pages you have visited and the links you have
  followed. We will use this information to make our website and the
  advertising displayed on it more relevant to your interests. We may also
  share this information with third parties for this purpose.</span></p>
  </td>
 </tr>
</table>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>33. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>You can find a list of
Cookies that we use in the Cookies Schedule.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>34. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>You can&nbsp;choose to
enable or disable Cookies in your internet browser. By default, most internet
browsers accept Cookies but this can be changed. For further details, please
see the help menu in your internet browser. You can switch off &nbsp;Cookies at
any time, however, you may lose any information that enables you to access the
Website more quickly and efficiently.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>35. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>You can choose to
delete Cookies at any time; however, you may lose any information that enables
you to access the Website more quickly and efficiently including, but not
limited to, personalisation settings.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>36. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>It is recommended that
you ensure that your internet browser is up-to-date and that you consult the
help and guidance provided by the developer of your internet browser if you are
unsure about adjusting your privacy settings.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>37. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>For more information
generally on cookies, including how to disable them, please refer to
aboutcookies.org. You will also find details on how to delete cookies from your
computer.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>General</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>38. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>You may not transfer
any of your rights under this Privacy Policy to any other person. We may
transfer our rights under this Privacy Policy where we reasonably believe your
rights will not be affected.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>39. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>If any court or
competent authority finds that any provision of this Privacy Policy (or part of
any provision) is invalid, illegal or unenforceable, that provision or
part-provision will, to the extent required, be deemed to be deleted, and the
validity and enforceability of the other provisions of this Privacy Policy will
not be affected.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
5.25pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>40. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>Unless otherwise
agreed, no delay, act or omission by a party in exercising any right or remedy
will be deemed a waiver of that, or any other, right or remedy.</span></p>

<p class=olclausesli style='margin-top:0cm;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>41. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:10.5pt'>This Agreement will be
governed by and interpreted according to the law of&nbsp;<span
class=htmlGeneratedanynoth1Character>England and Wales</span>. All disputes
arising under the Agreement will be subject to the exclusive jurisdiction of
the&nbsp;<span class=htmlGeneratedanynoth1Character>English and Welsh courts</span>.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Changes to This Privacy Policy</span></b></p>

<p class=olclausesli style='margin-top:10.5pt;margin-right:35.35pt;margin-bottom:
10.5pt;margin-left:35.45pt;text-indent:0cm;line-height:12.0pt'><span
lang=EN-US style='font-size:10.5pt'>42. <span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=htmlGeneratedanynoth1Character><span lang=EN-US
style='font-size:10.5pt'>MSLM LIMITED (trading as Crescent Convenience)</span></span><span
lang=EN-US style='font-size:10.5pt'>&nbsp;reserves the right to change this
Privacy Policy as we may deem necessary from time to time or as may be required
by law. Any changes will be immediately posted on the Website and you are
deemed to have accepted the terms of the Privacy Policy on your first use of
the Website following the alterations.&nbsp;<span
class=htmlGeneratedanynoth1Character><span style='display:none'>&nbsp;<br>
&nbsp;</span></span>&nbsp;<span class=htmlGeneratedanynoth1Character><span
style='display:none'>&nbsp;<br>
&nbsp;</span></span>&nbsp;You may contact&nbsp;<span
class=htmlGeneratedanynoth1Character>MSLM LIMITED (trading as Crescent
Convenience)</span>&nbsp;by email at&nbsp;<span
class=htmlGeneratedanynoth1Character>contact@crescentconvenience.com</span></span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>This Privacy Policy was created on&nbsp;<span
class=htmlGeneratedanynoth1Character><b>24 November 2024</b></span>.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-right:35.35pt;page-break-before:
always'><span lang=EN-US style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:27.0pt;margin-right:35.35pt;
margin-bottom:11.2pt;margin-left:35.45pt'><b><span lang=EN-US style='font-size:
13.5pt'>Cookies</span></b></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>Below is a list of the cookies that we use. We have
tried to ensure this is complete and up to date, but if you think that we have
missed a cookie or there is any discrepancy, please let us know.</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:10.5pt'>Strictly
necessary</span></b></span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>We use the following strictly necessary cookies:</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<table class=htmlGeneratedanynoth1Table border=1 cellspacing=0 cellpadding=0
 width=699 style='width:524.25pt;margin-left:1.5pt;border-collapse:collapse;
 border:none'>
 <tr>
  <td width="40%" style='width:40.0%;border:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Description</span></b></span></p>
  </td>
  <td style='border:inset gray 1.0pt;border-left:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Purpose</span></b></span></p>
  </td>
 </tr>
 <tr>
  <td style='border:inset gray 1.0pt;border-top:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>__stripe_mid&nbsp;</span></p>
  </td>
  <td style='border-top:none;border-left:none;border-bottom:inset gray 1.0pt;
  border-right:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>This cookie is used by Stripe
  (a payment processor) to facilitate payment services, specifically for fraud
  prevention and transaction security. It is considered strictly necessary for
  processing payments on websites that use Stripe.&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td style='border:inset gray 1.0pt;border-top:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>__stripe_sid</span></p>
  </td>
  <td style='border-top:none;border-left:none;border-bottom:inset gray 1.0pt;
  border-right:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>This cookie is also used by
  Stripe for fraud prevention and payment session tracking. It is strictly
  necessary for the secure functioning of Stripe's payment services.</span></p>
  </td>
 </tr>
  <tr>
  <td style='border:inset gray 1.0pt;border-top:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>m</span></p>
  </td>
  <td style='border-top:none;border-left:none;border-bottom:inset gray 1.0pt;
  border-right:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>This cookie is also used by
  Stripe for fraud prevention and payment session tracking. It is strictly
  necessary for the secure functioning of Stripe's payment services.</span></p>
  </td>
 </tr>
</table>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:10.5pt'>Functionality</span></b></span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>We use the following functionality cookies:</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<table class=htmlGeneratedanynoth1Table border=1 cellspacing=0 cellpadding=0
 width=699 style='width:524.25pt;margin-left:1.5pt;border-collapse:collapse;
 border:none'>
 <tr>
  <td width="30%" style='width:30.0%;border:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Description</span></b></span></p>
  </td>
  <td style='border:inset gray 1.0pt;border-left:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Purpose</span></b></span></p>
  </td>
 </tr>
 <tr>
  <td style='border:inset gray 1.0pt;border-top:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  class=htmlGeneratedanynoth1Character><span lang=EN-US>N/A</span></span></p>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>&nbsp;</span></p>
  </td>
  <td style='border-top:none;border-left:none;border-bottom:inset gray 1.0pt;
  border-right:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  class=htmlGeneratedanynoth1Character><span lang=EN-US>N/A</span></span></p>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>&nbsp;</span></p>
  </td>
 </tr>
</table>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:10.5pt'>Analytical/Performance</span></b></span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>We use the following analytical/performance cookies:</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<table class=htmlGeneratedanynoth1Table border=1 cellspacing=0 cellpadding=0
 width=699 style='width:524.25pt;margin-left:1.5pt;border-collapse:collapse;
 border:none'>
 <tr>
  <td width="30%" style='width:30.0%;border:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Description</span></b></span></p>
  </td>
  <td style='border:inset gray 1.0pt;border-left:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Purpose</span></b></span></p>
  </td>
 </tr>
 <tr>
  <td style='border:inset gray 1.0pt;border-top:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>_ga&nbsp;</span></p>
  </td>
  <td style='border-top:none;border-left:none;border-bottom:inset gray 1.0pt;
  border-right:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>This cookie is used by Google
  Analytics to distinguish users and track website usage. It is not strictly
  necessary as it is used for analytics and performance tracking.&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td style='border:inset gray 1.0pt;border-top:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>_ga_&lt;property-id&gt;</span></p>
  </td>
  <td style='border-top:none;border-left:none;border-bottom:inset gray 1.0pt;
  border-right:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>This is a variation of the _ga
  cookie for a specific Google Analytics property or application. It is also
  not strictly necessary and serves the same analytics purpose.</span></p>
  </td>
 </tr>
</table>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
class=htmlGeneratedanynoth1Character><b><span lang=EN-US style='font-size:10.5pt'>Targeting</span></b></span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>We use the following targeting cookies:</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<table class=htmlGeneratedanynoth1Table border=1 cellspacing=0 cellpadding=0
 width=699 style='width:524.25pt;margin-left:1.5pt;border-collapse:collapse;
 border:none'>
 <tr>
  <td width="30%" style='width:30.0%;border:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Description</span></b></span></p>
  </td>
  <td style='border:inset gray 1.0pt;border-left:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=MsoNormal align=center style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt;text-align:center;
  line-height:12.0pt'><span class=htmlGeneratedanynoth1Character><b><span
  lang=EN-US style='font-size:10.5pt;color:black'>Purpose</span></b></span></p>
  </td>
 </tr>
 <tr>
  <td style='border:inset gray 1.0pt;border-top:none;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  class=htmlGeneratedanynoth1Character><span lang=EN-US>N/A</span></span></p>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>&nbsp;</span></p>
  </td>
  <td style='border-top:none;border-left:none;border-bottom:inset gray 1.0pt;
  border-right:inset gray 1.0pt;padding:.75pt 1.1pt .75pt 1.1pt'>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  class=htmlGeneratedanynoth1Character><span lang=EN-US>N/A</span></span></p>
  <p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
  margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span
  lang=EN-US style='font-size:10.5pt;color:black'>&nbsp;</span></p>
  </td>
 </tr>
</table>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt'>&nbsp;</span></p>

<p class=htmlGeneratedanynoth1 style='margin-top:0cm;margin-right:35.35pt;
margin-bottom:0cm;margin-left:35.45pt;margin-bottom:.0001pt'><span lang=EN-US
style='font-size:10.5pt;display:none'>&nbsp;</span></p>

</div>

</body>

</html>

  `;

  return <div>{parse(htmlContent)}</div>;
}

export default LegalPrivacy;
