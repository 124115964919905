import React from "react";

export const DismissableBox = ({ isVisible, title, message, onDismiss }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="p-4 mb-6 bg-blue-100 border-l-4 border-blue-500 text-blue-700 rounded-md shadow-md">
      <div className="flex justify-between items-start">
        <div>
          <h4 className="font-bold">{title}</h4>
          <p>{message}</p>
        </div>
        <button
          onClick={onDismiss}
          className="text-blue-700 hover:text-blue-900 font-bold"
          aria-label="Dismiss"
        >
          ✕
        </button>
      </div>
    </div>
  );
};
