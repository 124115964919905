import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const StarRating = ({ rating, maxStars = 5, size = 'text-lg', color = 'text-yellow-500' }) => {
  const filledStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5;
  const emptyStars = maxStars - filledStars - (halfStar ? 1 : 0);

  return (
    <div className="flex">
      {Array.from({ length: filledStars }).map((_, index) => (
        <FaStar key={`filled-${index}`} className={`${size} ${color}`} />
      ))}

      {halfStar && (
        <FaStarHalfAlt key="half-star" className={`${size} ${color}`} />
      )}

      {Array.from({ length: emptyStars }).map((_, index) => (
        <FaRegStar key={`empty-${index}`} className={`${size} ${color}`} />
      ))}
    </div>
  );
};

export default StarRating;
